<template>
  <div>
    <Navbar :page="!$store.state.user.cliente ? 'Mapeamento de atividades de tratamento' : 'Questionários de mapeamento'" video="https://www.youtube.com/watch?v=qF0vAObm26s" />
    <div class="mx-4 my-4 md:mx-8 md:my-8">
      <div v-if="!$store.state.user.cliente && total > 0" class="grid grid-cols-12 gap-6 mb-3">
        <div class="col-span-12 md:col-span-5 rounded-lg px-1 py-2">
            <apexchart v-if="resumo" height="240" type="donut" :options="optionsGeral" :series="seriesGeral"></apexchart>
        </div>
        
        <div class="col-span-12 md:col-span-7 py-8 px-8 bg-gray-100">
          <h2 class="text-2xl mb-3 font-semibold">Informações gerais</h2>
          <ul>
            <li>
              Atividades Mapeadas: {{total}}
            </li>
            <li>
              Atividades com dados sensíveis: {{resumo ? formatIntNumber(resumo.qtdComDadosSensiveis|| 0) || 0 : 0}}
            </li>
            <li>
              Quantidade de titulares envolvidos: {{resumo ? formatIntNumber(resumo.qtdTitulares|| 0) || 0 : 0}}
            </li>
            <li>
              Quantidade de tipos de dados envolvidos: {{resumo ? resumo.qtdTipoDados || 0 : 0}}
            </li>
          </ul>
        </div>
      </div>

      <div class="grid grid-cols-12 gap-2">
        <div class="col-span-12 md:col-span-1">
          <router-link v-if="!$store.state.user.cliente" :to="`/${route_navigation}/gerar`" type="button" 
            :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }"
            class="inline-block transition duration-200 bg-yellow-300 hover:bg-yellow-400 
            focus:bg-yellow-700 focus:shadow-sm focus:ring-4 
            focus:ring-yellow-500 focus:ring-opacity-50 text-white py-2 
            rounded-lg text-sm shadow-sm hover:shadow-md w-full font-semibold text-center
            mb-2
            "
          >
              <span class="inline-block mr-2">Gerar QMA</span>
          </router-link>
        </div>

        <div class="col-span-12 md:col-span-3 2xl:col-span-2">
          <a v-if="!$store.state.user.cliente" :href="`${url_api}/v1/${route_backend}/exportar/${$store.state.empresa._id}?token=${$store.state.token}`" target= "_blank" rel="noreferrer" class="bg-blue-600 hover:bg-blue-700 focus:bg-blue-700 focus:shadow-sm focus:ring-4 text-white w-full flex py-2 rounded-lg text-sm shadow-sm hover:shadow-md font-semibold justify-center">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mt-1 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10" />
              </svg>
              Download atividades 
          </a>
        </div>

        <!--<div class="col-span-12 md:col-span-3 2xl:col-span-2"> 
          <router-link v-if="!$store.state.user.cliente" :to="`/${route_navigation}/importar`" class="bg-green-400 hover:bg-green-500 focus:bg-green-500 focus:shadow-sm focus:ring-4 text-white w-full flex py-2 rounded-lg text-sm shadow-sm hover:shadow-md font-semibold justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mt-1 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
            </svg> 
            Importar atividades 
          </router-link>
        </div>-->

        <div class="col-span-full" v-if="isRoot()">
          <div class="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-5">
            <div class="text-center">
              <svg class="mx-auto text-green-600" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" stroke="currentColor" viewBox="0 0 256 256"><path d="M200,24H72A16,16,0,0,0,56,40V64H40A16,16,0,0,0,24,80v96a16,16,0,0,0,16,16H56v24a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V40A16,16,0,0,0,200,24Zm-40,80h40v48H160Zm40-16H160V80a16,16,0,0,0-16-16V40h56ZM72,40h56V64H72ZM40,80H144v79.83c0,.06,0,.11,0,.17s0,.11,0,.17V176H40ZM72,192h56v24H72Zm72,24V192a16,16,0,0,0,16-16v-8h40v48ZM65.85,146.88,81.59,128,65.85,109.12a8,8,0,0,1,12.3-10.24L92,115.5l13.85-16.62a8,8,0,1,1,12.3,10.24L102.41,128l15.74,18.88a8,8,0,0,1-12.3,10.24L92,140.5,78.15,157.12a8,8,0,0,1-12.3-10.24Z"></path></svg>
              <div class="mt-4 flex text-sm leading-6 text-gray-600">
                <label for="file-upload" class="relative cursor-pointer rounded-md bg-white font-semibold text-green-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-green-600 focus-within:ring-offset-2 hover:text-green-500">
                  <span>Importar do excel</span>
                  <input ref="fileInput" accept=".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" @change="submitFile" id="file-upload" name="file-upload" type="file" class="sr-only">
                </label>
              </div>
              <p class="text-xs leading-5 text-gray-600">Apenas XLSX são aceitos</p>
            </div>
          </div>
        </div>

      </div>

      <div class="grid grid-cols-12 gap-6">
        <div class="col-span-12 md:col-span-3 2xl:col-span-3">
          <button @click="enviarSelecionados" v-if="!$store.state.user.cliente && selecionados.length" :to="`/${route_navigation}/gerar`" type="button" class="mt-3 transition duration-200 bg-green-600 hover:bg-green-700 text-white py-2 rounded-lg text-sm shadow-sm hover:shadow-md w-full font-semibold text-center">
            <span class="inline-block mr-2">Enviar QMA para responsável</span>
          </button>
        </div>
        <div class="col-span-12 md:col-span-3 2xl:col-span-3">
          <button @click="excluirSelecionadas" v-if="!$store.state.user.cliente && selecionados.length && !desativadas" type="button" class="mt-3 transition duration-200 bg-red-500 hover:bg-red-700 text-white py-2 rounded-lg text-sm shadow-sm hover:shadow-md w-full font-semibold text-center">
            <span class="inline-block">Excluir selecionadas</span>
          </button>
          <button @click="excluirSelecionadasParaSempre" v-if="!$store.state.user.cliente && selecionados.length && desativadas" type="button" class="mt-3 transition duration-200 bg-red-500 hover:bg-red-700 text-white py-2 rounded-lg text-sm shadow-sm hover:shadow-md font-semibold text-center w-full">
            <span class="inline-block">Excluir selecionadas para sempre</span>
          </button>
        </div>
      </div>

      <br /><small class="text-sm_2">QMA: Questionário de Mapeamento de Atividades</small>

      <div class="grid grid-cols-12 gap-4 mb-3">
        <div class="col-span-12 md:col-span-2 mb-1 mt-1">
            <input v-model="busca" type="text" name="busca" id="busca" placeholder="Buscar atividade" class="mt-1 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
        </div>
        <!--<div class="col-span-12 md:col-span-3 mb-1 mt-1">
          <select v-model="tipo_responsavelQMA" name="tipo_responsavelQMA" id="tipo_responsavelQMA" class="mt-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
              <option default :value="null">Todos os responsáveis pelo preenchimento</option>
              <option value="consultor">Próprio consultor (responsável do login administrador)</option>
              <option value="profissional">Outro profissional no projeto</option>
              <option value="pessoa">Uma pessoa na empresa</option>
          </select>
        </div>-->
        <div v-if="tipo_responsavelQMA === 'pessoa'" class="col-span-12 md:col-span-3 mb-1 mt-1">
            <select v-model="pessoa_responsavelQMA" name="pessoa_responsavelQMA" id="pessoa_responsavelQMA" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                <option default :value="null">Todos os responsáveis pelo preenchimento</option>
                <option v-for="pessoa in pessoas" :key="pessoa._id" :value="pessoa._id">{{ pessoa.nome }}</option>
            </select>
        </div>
        <div v-if="tipo_responsavelQMA === 'profissional'" class="col-span-12 md:col-span-3 mb-1 mt-1">
            <select v-model="profissional_responsavelQMA" name="profissional_responsavelQMA" id="profissional_responsavelQMA" class="mt-2 mr-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                <option default :value="null">Todos os responsáveis pelo preenchimento</option>
                <option v-for="profissional in profissionais" :key="profissional._id" :value="profissional._id">{{ profissional.nome }}</option>
            </select>
        </div>
        <div class="col-span-12 md:col-span-3 mb-1 mt-1">
          <select v-model="responsavel" name="responsavel" id="responsavel" class="mt-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
              <option default :value="null">Por resp. pela atividade mapeada</option>
              <option v-for="responsavel in pessoas" :key="responsavel._id" :value="responsavel._id">{{ responsavel.nome }}</option>
          </select>
        </div>
        <div class="col-span-12 md:col-span-2 mb-1 mt-1">
          <select v-model="setor" name="setor" id="setor" class="mt-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
              <option default :value="null">Todos os setores</option>
              <option v-for="setor in setores" :key="setor._id" :value="setor._id">{{ setor.nome }}</option>
          </select>
        </div>
        <div class="col-span-12 md:col-span-2 mb-1 mt-1">
          <select v-model="status" name="status" id="status" class="mt-1 w-full py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
              <option default :value="null">Todos os status</option>
              <option default value="Gerada">Gerada</option>
              <option default value="Pendente">Pendente</option>
              <option default value="Respondido">Respondido</option> 
              <option default value="Revisado">Revisado</option> 
              <option default value="Finalizado">Finalizado</option>
          </select>
        </div>
        <div class="col-span-12 md:col-span-2 mb-1 mt-2">
          <button @click="updateFiltro" class="bg-gray-500 hover:bg-gray-600 focus:bg-gray-700 focus:shadow-sm focus:ring-opacity-50 text-white py-2 rounded-3xl text-sm shadow-sm hover:shadow-md w-full font-semibold text-center">Buscar </button>
        </div>
        <div class="col-span-12 md:col-span-2 mb-1 mt-2" v-if="!$store.state.user.cliente">
          <label for="naoContemAvaliacao" class="block text-sm font-medium mt-2">
            <input type="checkbox" v-model="desativadas" class="rounded-sm" id="naoContemAvaliacao">
            <span class="ml-2">
                Visualizar desativadas
            </span>
          </label>
        </div>
      </div>

      <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                  <tr>
                    <th v-if="!$store.state.user.cliente" scope="col" class="px-2 py-3 text-left text-sm font-medium text-gray-500 tracking-wider cursor-pointer">
                      <svg @click="selecionarTodos" xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="#000000" viewBox="0 0 256 256"><path d="M220,48V208a12,12,0,0,1-12,12H136a4,4,0,0,1,0-8h72a4,4,0,0,0,4-4V48a4,4,0,0,0-4-4H48a4,4,0,0,0-4,4v96a4,4,0,0,1-8,0V48A12,12,0,0,1,48,36H208A12,12,0,0,1,220,48ZM117.17,157.17,64,210.34,42.83,189.17a4,4,0,0,0-5.66,5.66l24,24a4,4,0,0,0,5.66,0l56-56a4,4,0,0,0-5.66-5.66Z"></path></svg>
                    </th>
                    <th scope="col" class="px-2 py-3 text-left text-sm font-medium text-gray-500 tracking-wider">
                      REF
                    </th>
                    <th scope="col" class="px-2 py-3 text-left text-sm font-medium text-gray-500 tracking-wider">
                      Atividade / Processo
                    </th>
                    <th v-if="!$store.state.user.cliente" scope="col" class="px-2 py-3 text-left text-sm font-medium text-gray-500 tracking-wider">
                      Setor
                    </th>
                    <!--<th scope="col" class="px-2 py-3 text-left text-sm font-medium text-gray-500 tracking-wider">
                      Versão
                    </th>-->
                    <th v-if="!$store.state.user.cliente" scope="col" class="px-2 py-3 text-left text-sm font-medium text-gray-500 tracking-wider">
                      Responsável preenchimento
                    </th>
                    <!--<th v-if="!$store.state.user.cliente" scope="col" class="px-2 py-3 text-left text-sm font-medium text-gray-500 tracking-wider">
                      Responsável atividade
                    </th>-->
                    <th scope="col" class="px-2 py-3 text-left text-sm font-medium text-gray-500 tracking-wider">
                      Atualização
                    </th>
                    <th scope="col" class="px-2 py-3 text-left text-sm font-medium text-gray-500 tracking-wider">
                      Risco preliminar
                    </th>
                    <th scope="col" class="px-2 py-3 text-left text-sm font-medium text-gray-500 tracking-wider">
                      Status
                    </th>
                    <th scope="col" class="px-2 py-3 text-left text-sm font-medium text-gray-500 tracking-wider">
                      Opções
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr v-for="item in list" :key="item._id" :class="item.respondido && $store.state.user.cliente || item.finalizado ? 'bg-green-100': '' ">
                   
                    <td v-if="!$store.state.user.cliente" class="px-2 py-3 whitespace-nowrap">
                      <div class="flex items-center">
                        <input :checked="selecionados.map(el => el._id).indexOf(item._id) > -1" @change="(e) => selecionadosControl(item, e)" type="checkbox" class="rounded-sm">
                      </div>
                    </td>

                    <td class="px-2 py-3 whitespace-nowrap">
                      <div class="flex items-center">
                        <div class="text-sm_2 text-gray-500">
                          {{item.ref ? item.ref : ''}}
                        </div>
                      </div>
                    </td>

                    <td class="px-2 py-3">
                      <div class="flex items-center">
                        <div class="text-sm_2 text-gray-500">
                          {{item.nome ? item.nome : ''}}
                        </div>
                      </div>
                    </td>

                    <td v-if="!$store.state.user.cliente" class="px-2 py-3">
                      <div class="flex items-center">
                        <div class="text-sm_2 text-gray-500">
                          {{item.setor ? item.setor.nome : ''}}
                        </div>
                      </div>
                    </td>

                    <!--<td class="px-2 py-3 whitespace-nowrap">
                      <div class="flex items-center">
                        <div v-if="item.versao === 'Inicial'" class="text-sm_2 font-semibold text-pendente">
                          {{item.versao ? item.versao : ''}}
                        </div>
                        <div v-else class="text-sm_2 font-semibold text-concluido">
                          {{item.versao ? item.versao : ''}}
                        </div>
                      </div>
                    </td>--->

                    <td v-if="!$store.state.user.cliente" class="px-2 py-3 text-sm font-medium text-gray-500">
                      <div class="flex items-center">
                        <div v-if="item.profissional_responsavelQMA" class="text-sm font-semibold text-gray-500">
                          Profissional: {{ item.profissional_responsavelQMA && item.profissional_responsavelQMA.nome ? item.profissional_responsavelQMA.nome : '' }}
                        </div>
                        <div v-if="item.pessoa_responsavelQMA" class="text-sm font-semibold text-gray-500">
                          Pessoa: {{ item.pessoa_responsavelQMA && item.pessoa_responsavelQMA.nome ? item.pessoa_responsavelQMA.nome : '' }}
                        </div>
                        <div v-if="item.tipo_responsavelQMA === 'consultor'" class="text-sm font-semibold text-gray-500">
                          Consultor: {{ item.consultor_responsavelQMA ? item.consultor_responsavelQMA : 'Login Administrador' }}
                        </div>
                      </div>
                    </td>

                    <!--<td v-if="!$store.state.user.cliente" class="px-2 py-3 text-sm font-medium text-gray-500">
                      <div class="flex items-center">
                        <div class="text-sm font-semibold text-gray-500">
                          {{ item.responsavel && item.responsavel.nome ? item.responsavel.nome : '' }}
                        </div>
                      </div>
                    </td>--->

                    
                    <td class="px-2 py-3">
                      <div class="flex items-center">
                        <div v-if="item.dataAtualizacao" class="text-sm_2 text-gray-500">
                          {{ item.dataAtualizacao  | moment("DD/MM/YYYY HH:mm")}}
                        </div>
                      </div>
                    </td>
                    
                    <td class="px-2 py-3 whitespace-nowrap text-sm text-center">
                      <span class="px-1 bg-green-500 text-white" v-if="item.avaliacao_preliminar.grauderisco === 'muitobaixa'">Muito Baixo</span>
                      <span class="px-1 bg-green-400 text-white" v-if="item.avaliacao_preliminar.grauderisco === 'baixo'">Baixo</span>
                      <span class="px-1 bg-yellow-500 text-white" v-if="item.avaliacao_preliminar.grauderisco === 'medio'">Médio</span>
                      <span class="px-1 bg-red-400 text-white" v-if="item.avaliacao_preliminar.grauderisco === 'alto'">Alto</span>
                      <span class="px-1 bg-red-500 text-white" v-if="item.avaliacao_preliminar.grauderisco === 'muitoalto'">Muito Alto</span>
                    </td>

                    <td class="px-2 py-3 whitespace-nowrap">
                      <div class="flex items-center">
                        <div v-if="item.status === 'Pendente'" class="text-sm_2 font-semibold text-andamento">
                          {{ item.status ? item.status: ''}}
                        </div>
                        <div v-if="item.status === 'Revisado'" class="text-sm_2 font-semibold text-indigo-500">
                          {{ item.status ? item.status: ''}}
                        </div>
                        <div v-else-if="item.status === 'Respondido'" class="text-sm_2 font-semibold text-pendente">
                          {{ item.status ? item.status: ''}}
                        </div>
                        <div v-else-if="item.status === 'Gerada'" class="text-sm_2 font-semibold text-danger">
                          {{ item.status ? item.status: ''}}
                        </div>
                        <div v-else-if="item.status === 'Finalizado'" class="text-sm_2 font-semibold text-blue-500">
                          {{ item.status ? item.status: ''}}
                        </div>
                      </div>
                    </td>
                  
                    <td v-if="!desativadas" class="px-2 py-3 text-sm font-medium">
                      <button 
                       v-if="!$store.state.user.cliente && !item.gerada"
                      @click="gerarParaResponsavel(item)" 
                       class="text-white hover:bg-green-700 bg-green-600 py-1 px-3 mx-1 my-1"
                       >
                        Enviar
                      </button>

                      <button 
                       v-if="!$store.state.user.cliente && item.gerada && item.tipo_responsavelQMA !== 'consultor'"
                       :disabled="item.tipo_responsavelQMA === 'consultor' || !item.revisado || item.profissional_responsavelQMA && item.profissional_responsavelQMA._id && $store.state.user._id.toString() === item.profissional_responsavelQMA._id.toString() ? true : false" 
                       :class="{ 'opacity-50': verifOpacity(item) }"
                       @click="gerarParaResponsavel(item, 'reenviar')" 
                       class="text-white hover:bg-green-700 bg-green-600 py-1 px-3 mx-1 my-1"
                       >
                        Reenviar
                      </button>

                      <router-link 
                        :to="`/${route_navigation}/form/${item._id}`"
                        class="inline-block text-white hover:bg-blue-500 bg-blue-700 py-1 px-2 mx-1 my-1">
                        {{ getNameFuncao(item) }}
                      </router-link>

                      <button
                        v-if="!$store.state.user.cliente"
                        @click="duplicar(item._id)" 
                        type="button" 
                        class="text-white hover:bg-yellow-500 bg-yellow-400 py-1 px-2 mx-1 my-1">
                        Duplicar
                      </button>

                      <button
                        v-if="!$store.state.user.cliente"
                        @click="remove(item._id)" 
                        type="button" 
                        class="text-white hover:bg-red-500 bg-red-700 py-1 px-2 mx-1 my-1">
                        Desativar
                      </button>

                      <router-link 
                        :to="`/${route_navigation}/evidencias/${item._id}`"
                        class="inline-block text-white hover:bg-black bg-black py-1 px-2 mx-1 my-1">
                        Evidências
                      </router-link>

                    </td>
                    <td v-else class="px-2 py-3 text-sm font-medium">
                      <router-link 
                        :to="`/${route_navigation}/form/${item._id}`"
                        class="inline-block text-white hover:bg-blue-500 bg-blue-700 py-1 px-2 mx-1 my-1">
                        Atualizar
                      </router-link>

                      <button
                        @click="ativar(item._id)" 
                        type="button" 
                        class="text-white hover:bg-green-600 bg-green-500 py-1 px-2 mx-1 my-1">
                        Ativar
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <label for="perPage">Itens por página

              <select class="w-20 mt-2 mr-1 py-2 px-3 border border-gray-300 bg-white rounded-3xl shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm" @change="start" v-model="perPage">
                <option :value="5">5</option>
                <option :value="10">10</option>
                <option :value="20">20</option>
                <option :value="50">50</option>
                <option :value="100">100</option>
              </select>

            </label>
            <pagination v-if="total > 0" v-model="query.page" :per-page="perPage" :records="total" @paginate="setPage" class="pagination" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      route_navigation: 'mapeamento',
      route_backend: 'atividades',
      status: null,
      responsavel: null,
      tipo_responsavelQMA: null,
      pessoa_responsavelQMA:  null,
      profissional_responsavelQMA: null,
      resumo: null,
      selecionados: [],
      optionsGeral: {
        chart: {
          id: 'chartGeral',
          type: 'donut',
        },
        labels: ["Gerados", "Pendentes", "Respondidos", "Revisados", "Finalizados"],
        colors: ['#FF4560', '#F3B01B', '#008FFB', '#775DD0', '#00E396']
      },
      seriesGeral: [0, 0, 0, 0, 0],
      qtdDados: 0,
      qtdDadosSensiveis: 0,
      qtdDadosCriticos: 0,
      list: [],
      page: 1,
      perPage: 20,
      busca: '',
      desativadas: false,
      setores: [],
      profissionais: [],
      pessoas: [],
      setor: null,
      total: 0,
      query: { page: 1, limit: 10, skip: 0, desativadas: false },
    }
  },
  methods: {
    async ativar(id){
      const form = { _id: id, ativo: true }
      const req = await this.$http.put(`/v1/${this.route_backend}`, form);
      
      if (!req.data.success) {
        this.$vToastify.error(req.data.err);
      }else{
        this.$vToastify.success('Atividade ativada com sucesso');
      }
      this.start();
    },
    async submitFile(event) {

      this.$confirm({
        title: 'Importar de planilha excel (xlsx)',
        message: `Deseja importar o conteúdo da planilha selecionada? Essa ação não poderá ser desfeita.`,
        button: {
            no: 'Não',
            yes: 'Sim',
        },
        callback: async confirm => {
          if(!confirm) return;
          
          // Verifica se o arquivo foi selecionado
          if (event.target.files.length > 0) {
            const file = event.target.files[0];
    
            const formData = new FormData();
            formData.append('file', file);
            formData.append('empresa', JSON.stringify(this.$store.state.empresa));
    
            const response = await this.$http.postFile(`/v1/${this.route_backend}/importarMapeamento`, formData);
            console.log('Arquivo enviado com sucesso', response);
          }
    
          this.$refs.fileInput.value = '';
    
          this.start();

          this.$vToastify.success("Importação concluída com sucesso!");
        }
      });

    },
    async start() {
      this.selecionados = [];

      this.query.limit = this.perPage;
      const listReq = await this.$http.post(`/v1/${this.route_backend}/list`, { busca: this.busca, setor: this.setor, desativadas: this.desativadas, status: this.status, responsavel: this.responsavel, profissional_responsavelQMA: this.profissional_responsavelQMA, pessoa_responsavelQMA: this.pessoa_responsavelQMA, tipo_responsavelQMA: this.tipo_responsavelQMA, skip: this.query.skip, limit: this.query.limit });
      this.list = listReq.data.data;
      this.total = listReq.data.total;
    },

    async grafico() {
      const resReq = await this.$http.post(`/v1/${this.route_backend}/resumo`);

      this.seriesGeral = [resReq.data.gerados, resReq.data.pendentes, resReq.data.respondidos, resReq.data.revisados, resReq.data.finalizados];
      this.qtdDados = resReq.data.qtdDados;
      this.qtdDadosSensiveis = resReq.data?.qtdDadosSensiveis;
      this.qtdDadosCriticos = resReq.data.qtdDadosCriticos;
      this.resumo = resReq.data;
    },

    async enviarSelecionados(){
      this.$confirm({
        title: 'Enviar questionários',
        message: `Deseja enviar os questionários para os responsáveis ?`,
        button: {
            no: 'Não',
            yes: 'Sim',
        },
        callback: async confirm => {
          if(!confirm) return;
          for(let i = 0; i < this.selecionados.length; i++){
            const selecionado = this.selecionados[i];
            if(!selecionado.gerada) await this.$http.put(`/v1/${this.route_backend}/`,{ _id: selecionado._id, gerada: true, status: 'Pendente' });
          }
          this.selecionados = [];
          this.start();
          this.grafico();
        }
      });
    },

    async gerarParaResponsavel(atividade, geracao) {
      this.$confirm({
        title: 'Enviar questionário',
        message: `Deseja enviar o questionário para o responsável ?`,
        button: {
            no: 'Não',
            yes: 'Sim',
        },
        callback: async confirm => {
          if(!confirm) return;

          let body = { _id: atividade._id, gerada: true, status: 'Pendente' };
          if(geracao === 'reenviar') body = { _id: atividade._id, reenviarMapeamento: true, status: 'Pendente' };

          await this.$http.put(`/v1/${this.route_backend}/`, body);
          this.start();
          this.grafico();
        }
      })
    },
    
    async remove(id) {
      this.$confirm({
        title: 'Desativar atividade',
        message: `Deseja desativar a atividade selecionada?`,
        button: {
            no: 'Não',
            yes: 'Sim',
        },
        callback: async confirm => {
          if(!confirm) return;
          await this.$http.delete(`/v1/${this.route_backend}/${id}`);
          this.$vToastify.success("Removido com sucesso!");
          this.start();
          this.grafico();
        }
      })
    },

    async excluirSelecionadas(){
      this.$confirm({
        title: 'Remover atividades selecionadas',
        message: `Deseja remover as atividades selecionadas ?`,
        button: {
            no: 'Não',
            yes: 'Sim',
        },
        callback: async confirm => {
          if(!confirm) return;
          await this.$http.post(`/v1/${this.route_backend}/selecionados/deletar`,{ selecionados: this.selecionados });
          this.$vToastify.success("Removido com sucesso!");
          this.start();
          this.grafico();
        }
      })
    },
    async excluirSelecionadasParaSempre(){
      this.$confirm({
        title: 'Remover atividades selecionadas sem a possibilidade de reverter',
        message: `Deseja remover as atividades selecionadas? (ESSA AÇÃO É IRREVERSÍVEL)`,
        button: {
            no: 'Não',
            yes: 'Sim',
        },
        callback: async confirm => {
          if(!confirm) return;
          await this.$http.post(`/v1/${this.route_backend}/selecionados/deletar4ever`,{ selecionados: this.selecionados });
          this.$vToastify.success("Removido com sucesso!");
          this.start();
          this.grafico();
        }
      })
    },

    async duplicar(atividade){
      this.$confirm({
        title: 'Duplicar atividade',
        message: `Deseja duplicar a atividade selecionada ?`,
        button: {
            no: 'Não',
            yes: 'Sim',
        },
        callback: async confirm => {
          if(!confirm) return;
          await this.$http.post(`/v1/${this.route_backend}/duplicar`,{ atividade });
          this.$vToastify.success("Duplicado com sucesso!");
          this.start();
          this.grafico();
        }
      })
    },

    updateFiltro(){
      console.log("update filtro");
      this.query.page = 1;
      this.query.limit = this.perPage;
      this.query.skip = 0;

      this.query = { page: this.query.page, limit: this.query.limit, skip: this.query.skip, busca: this.busca, desativadas: this.desativadas, setor: this.setor, status: this.status, responsavel: this.responsavel, profissional_responsavelQMA: this.profissional_responsavelQMA, pessoa_responsavelQMA: this.pessoa_responsavelQMA, tipo_responsavelQMA: this.tipo_responsavelQMA };
      this.$router.push({ path: `/mapeamento`, query: this.query });
    },

    async setPage(page) {
      console.log("SET PAGE");
      const skip = (page - 1) * this.perPage;
      const limit = this.perPage;

      this.query.page = page;
      this.query.skip = skip;
      this.query.limit = limit;
      this.query.busca = this.busca;
      this.query.setor = this.setor;
      this.query.pessoa_responsavelQMA = this.pessoa_responsavelQMA;
      this.query.profissional_responsavelQMA = this.profissional_responsavelQMA;
      this.query.responsavel = this.responsavel;
      this.query.tipo_responsavelQMA = this.tipo_responsavelQMA
      this.query.status = this.status;
      this.query.desativadas = this.desativadas;

      this.$router.push({ path: `/mapeamento`, query: this.query });
    },

    copyClipboard () {
      navigator.clipboard.writeText(this.link);
      this.$vToastify.success("Copiado com sucesso!");
    },

    async selecionarTodos(){
      if (!this.selecionados.length) {
        const listReq = await this.$http.post(`/v1/${this.route_backend}/list`, { all: true, busca: this.busca, setor: this.setor, desativadas: this.desativadas, status: this.status, responsavel: this.responsavel, profissional_responsavelQMA: this.profissional_responsavelQMA, pessoa_responsavelQMA: this.pessoa_responsavelQMA, tipo_responsavelQMA: this.tipo_responsavelQMA });
        this.selecionados = listReq.data.data;
      } else {
        this.selecionados = [];
      }
    },

    selecionadosControl(item, e){
      if (this.selecionados.map(el => el._id).indexOf(item._id) > -1 && !e.target.checked) {
        this.selecionados.splice(this.selecionados.map(el => el._id).indexOf(item._id), 1);
      } else {
        this.selecionados.push(item);
      }
    },

    getNameFuncao(item){
      if(item.tipo_responsavelQMA === 'consultor' && !item.revisado && item.status === 'Gerada') return 'Iniciar';
      if(this.$store.state.user.cliente) return 'Responder'
      if(this.$store.state.user.profissional && item.profissional_responsavelQMA && item.profissional_responsavelQMA._id.toString() === this.$store.state.user._id.toString()) return 'Responder';
      return item.revisado ? 'Atualizar' : item.gerada && item.revisado ? 'Revisar' : 'Atualizar';
    },

    verifOpacity(item){
      if(item.tipo_responsavelQMA === 'consultor') return true;
      if(!item.revisado) return true;
      if(!item.gerada) return true;
      if(item.profissional_responsavelQMA && item.profissional_responsavelQMA._id && item.profissional_responsavelQMA._id.toString() === this.$store.state.user._id.toString()) return true;
      return false;
    }

  },
  async beforeRouteUpdate(to, from, next) {
    next();

    if(this.$route.query.busca) this.busca = this.$route.query.busca;
    if(this.$route.query.setor) this.setor = this.$route.query.setor;
    if(this.$route.query.status) this.status = this.$route.query.status;
    if(this.$route.query.responsavel) this.responsavel = this.$route.query.responsavel;
    if(this.$route.query.profissional_responsavelQMA) this.profissional_responsavelQMA = this.$route.query.profissional_responsavelQMA;
    if(this.$route.query.pessoa_responsavelQMA) this.pessoa_responsavelQMA = this.$route.query.pessoa_responsavelQMA;
    if(this.$route.query.tipo_responsavelQMA) this.tipo_responsavelQMA = this.$route.query.tipo_responsavelQMA;
    if(this.$route.query.desativadas) this.desativadas = this.$route.query.desativadas === 'true' ? true : false;

    if(this.$route.query.page) this.query.page = parseInt(this.$route.query.page);
    if(this.$route.query.limit) this.query.limit = parseInt(this.$route.query.limit);
    if(this.$route.query.skip) this.query.skip = parseInt(this.$route.query.skip);

    this.start();
  },
  async beforeMount() {
    if(this.$route.query.busca) this.busca = this.$route.query.busca;
    if(this.$route.query.setor) this.setor = this.$route.query.setor;
    if(this.$route.query.status) this.status = this.$route.query.status;
    if(this.$route.query.responsavel) this.responsavel = this.$route.query.responsavel;
    if(this.$route.query.profissional_responsavelQMA) this.profissional_responsavelQMA = this.$route.query.profissional_responsavelQMA;
    if(this.$route.query.pessoa_responsavelQMA) this.pessoa_responsavelQMA = this.$route.query.pessoa_responsavelQMA;
    if(this.$route.query.tipo_responsavelQMA) this.tipo_responsavelQMA = this.$route.query.tipo_responsavelQMA;
    if(this.$route.query.desativadas) this.desativadas = this.$route.query.desativadas === 'true' ? true : false;

    if(this.$route.query.page) this.query.page = parseInt(this.$route.query.page);
    if(this.$route.query.limit) this.query.limit = parseInt(this.$route.query.limit);
    if(this.$route.query.skip) this.query.skip = parseInt(this.$route.query.skip);

    this.grafico();
    this.start();

    const listSetores = await this.$http.post(`/v1/setores/list`, { all: true });
    this.setores = listSetores.data.data;

    const pessoasReq = await this.$http.post(`/v1/pessoas/list`, { all: true });
    this.pessoas = pessoasReq.data.data;

    const profissionaisRef = await this.$http.post(`/v1/profissionais/listProfissionaisEmpresa`);  
    this.profissionais = profissionaisRef.data.data;

    this.$watch('desativadas', async (desativada) => {
      this.selecionados = [];
      const listReq = await this.$http.post(`/v1/${this.route_backend}/list`, { busca: this.busca, setor: this.setor, desativadas: desativada, status: this.status, responsavel: this.responsavel, profissional_responsavelQMA: this.profissional_responsavelQMA, pessoa_responsavelQMA: this.pessoa_responsavelQMA, tipo_responsavelQMA: this.tipo_responsavelQMA, skip: this.query.skip, limit: this.query.limit });
      this.list = listReq.data.data;
      this.total = listReq.data.total;
    });

    this.$watch('tipo_responsavelQMA', async () => {
      this.pessoa_responsavelQMA = null;
      this.profissional_responsavelQMA = null;
    });
  },
}
</script>